import { takeLatest, put } from 'redux-saga/effects';
import { STATUS, API, jsonToQueryString } from '@vezeeta/web-utils';
import Cookies from 'js-cookie';

import CoustomUrls from '../../urls';
import {
  UNITS,
  fetchUnitsSuccess,
  fetchUnitsFail,
  fetchUnitDetailsSuccess,
  fetchUnitDetailsFail,
  addEditUnitSuccess,
  addEditUnitFail
} from '../actions/units';

const auth = Cookies.get('AUTH_TOKEN');

function* fetchUnits(action) {
  if (auth) {
    const headers = [
      {
        key: 'languageId',
        value: '1'
      },
      {
        key: 'Authorization',
        value: `bearer ${auth}`
      }
    ];

    const body = {
      pageIndex: action.pageIndex,
      pageSize: action.pageSize
    };

    const apiWrapper = yield new API();
    const response = yield apiWrapper.get(
      `${CoustomUrls.getAllUnits}?${jsonToQueryString(body)}`,
      headers,
      false
    );
    if (STATUS.isSuccess(response.status)) {
      yield put(fetchUnitsSuccess(response.data));
    } else if (STATUS.isNotAuthorized(response.status)) {
      Cookies.remove('AUTH_TOKEN');
      window.location.href = '/';
    } else {
      yield put(fetchUnitsFail(response));
    }
  } else {
    Cookies.remove('AUTH_TOKEN');
    window.location.href = '/';
  }
}

function* fetchUnitDetails(action) {
  if (auth) {
    const headers = [
      {
        key: 'languageId',
        value: '1'
      },
      {
        key: 'Authorization',
        value: `bearer ${auth}`
      }
    ];

    const body = {
      unitKey: action.unitKey
    };

    const apiWrapper = yield new API();
    const response = yield apiWrapper.get(
      `${CoustomUrls.getUnitDetails}?${jsonToQueryString(body)}`,
      headers,
      false
    );
    if (STATUS.isSuccess(response.status)) {
      yield put(fetchUnitDetailsSuccess(response.data));
    } else if (STATUS.isNotAuthorized(response.status)) {
      Cookies.remove('AUTH_TOKEN');
      window.location.href = '/';
    } else {
      yield put(fetchUnitDetailsFail(response));
    }
  } else {
    Cookies.remove('AUTH_TOKEN');
    window.location.href = '/';
  }
}

function* addEditUnit(action) {
  if (auth) {
    const headers = [
      {
        key: 'Authorization',
        value: `bearer ${auth}`
      }
    ];
    const apiWrapper = yield new API();
    const response = yield apiWrapper.post(CoustomUrls.addEditUnit, action.unitObj, headers);

    if (STATUS.isSuccess(response.status)) {
      yield put(addEditUnitSuccess(response.data));
    } else if (STATUS.isNotAuthorized(response.status)) {
      Cookies.remove('AUTH_TOKEN');
      window.location.href = '/';
    } else {
      yield put(addEditUnitFail(response));
    }
  } else {
    Cookies.remove('AUTH_TOKEN');
    window.location.href = '/';
  }
}

function* unitsSaga() {
  yield takeLatest(UNITS.FETCH.LOAD, fetchUnits);
  yield takeLatest(UNITS.FETCH_DETAILS.LOAD, fetchUnitDetails);
  yield takeLatest(UNITS.ADD_EDIT.LOAD, addEditUnit);
}

export default unitsSaga;
