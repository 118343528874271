export const PROFILE = {
    LOAD: 'LOAD_PROFILE',
    LOADING: 'LOADING_PROFILE',
    LOADED: 'PROFILE_LOADED',
    FAILED: 'PROFILE_LOADING_FAILED',
    LOAD_CAMPAIGNS: 'LOAD_CAMPAIGNS',
    CAMPAIGNS_LOADED: 'CAMPAIGNS_LOADED',
    CAMPAIGNS_FAILED: 'CAMPAIGNS_LOAD_FAILED'
}

export const loadProfile = (type, payload) => ({
    type: !type ? PROFILE.LOAD : type,
    profile: payload,
})

export const loadCampaigns = payload => ({
    type: PROFILE.LOAD_CAMPAIGNS,
    payload,
})