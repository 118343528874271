import { GENERAL } from '../actions/general';

const initialState = {
  context:''
};

export default (state = initialState, {type, ...payload}) => {

  switch (type) {
    case GENERAL.CONTEXT:
      return {
        ...state,
        context: payload,
      };
    default:
      return state;
  }
};
