import { takeEvery, put } from 'redux-saga/effects';
import { API, STATUS, Urls } from '@vezeeta/web-utils';
import  CoustomUrls  from '../../urls'
import { MEDICINE } from '../actions/medicine';

function* getCategories({payload}) {
  try {
    const queryString = `?pageNumber=${payload.pageNumber}&pageSize=${payload.pageSize}`;
  
    const apiObj = new API();
    
    const res = yield apiObj.get(`${CoustomUrls.medicine.categories}${queryString}`, undefined, true);
    if (STATUS.isSuccess(res.status)) {
      yield put({ type: MEDICINE.CATEGORIES.SUCCEEDED, categories: res.response });
    } else {
      yield put({ type: MEDICINE.CATEGORIES.FAILED });
    }
  } catch (err) {
    yield put({ type: MEDICINE.CATEGORIES.FAILED });
  }
}

function* getTypes() {
  try {
    
    const apiObj = new API();
    
    const res = yield apiObj.get(`${CoustomUrls.medicine.types}`, undefined, true);
    if (STATUS.isSuccess(res.status)) {
      yield put({ type: MEDICINE.TYPES.SUCCEEDED, types: res.response });
    } else {
      yield put({ type: MEDICINE.TYPES.FAILED });
    }
  } catch (err) {
    yield put({ type: MEDICINE.TYPES.FAILED });
  }
}


export default function* medicine() {
  yield takeEvery(MEDICINE.CATEGORIES.LOADING, getCategories);
  yield takeEvery(MEDICINE.TYPES.LOADING, getTypes);
}
