import { AREAS } from '../actions/area'
const initialState = {
    areas: [],
    loaded: false,
    loading: false,
}

export default (state = initialState, {type, ...payload}) => {
    switch(type) {
        case AREAS.LOAD:
            return {
                ...state,
                loaded: false,
                loading: true,
            }
        case AREAS.LOAD_DONE: 
            return {
                ...state,
                areas: [...payload.areas],
                loaded: true,
                loading: false,
            }
        case AREAS.LOAD_FAILED:
            return {
                ...state,
                loaded: false,
                loading: false,
            }
        case AREAS.RESET: {
            //console.log('dasdsakdals    ')
            return {
                loaded: false,
                loading: false,
                areas:[]
            }
        }
          
        default: return state
    }
}