import { takeEvery, put } from 'redux-saga/effects';
import { API, STATUS, Urls } from '@vezeeta/web-utils';
import  CoustomUrls  from '../../urls'
import { COUNTRY } from '../actions/country';

function* getCountries() {
  try {
    const apiObj = new API();
    //const res = yield apiObj.get(Urls.getCountries, undefined, true);
    const res = yield apiObj.get(`${CoustomUrls.getCountries}`, undefined, true);
    if (STATUS.isSuccess(res.status)) {
      yield put({ type: COUNTRY.LOAD_DONE, countries: res.data });
    } else {
      yield put({ type: COUNTRY.LOAD_FAIL });
    }
  } catch (err) {
    yield put({ type: COUNTRY.LOAD_FAIL });
  }
}
function* getCountryList() {
  try {
    const apiObj = new API();
    const res = yield apiObj.get(Urls.getCountryList, undefined, true);
    if (STATUS.isSuccess(res.status)) {
      yield put({ type: COUNTRY.LOAD_LIST_DONE, countries: res.data });
    } else {
      yield put({ type: COUNTRY.LOAD_LIST_FAIL });
    }
  } catch (err) {
    yield put({ type: COUNTRY.LOAD_LIST_FAIL });
  }
}

export default function* country() {
  yield takeEvery(COUNTRY.LOAD, getCountries);
  yield takeEvery(COUNTRY.LOAD_LIST, getCountryList);
}
