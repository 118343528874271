import { CAMPAIGN } from '../actions/summary'
const initialState = {
    message: '',
    stopping: false,
    stopped: false,
    success: false
}

export default (state = initialState, { type, ...payload}) => {
    switch(type) {
        case CAMPAIGN.STOP_CAMPAIGN:
            return {
                ...state,
                stopping: true,
                stopped: false
            }
        case CAMPAIGN.STOP_CAMPAIGN_FAILED: 
            return {
                ...state,
                message: payload.message,
                stopping: false,
                stopped: true
            }
        case CAMPAIGN.STOP_CAMPAIGN_SUCCESS:
            return {
                ...state,
                message: payload.message,
                stopping: false,
                stopped: false,
                success: true
            }
        default: return state
    }
}