import { takeLatest, put } from 'redux-saga/effects'
import { API, STATUS } from '@vezeeta/web-utils'
import CoustomUrls from '../../urls'

import { PROFILE } from '../actions/profile'

function* loadCampaignsSaga({ payload }) {
  const api = new API()

  const res = yield api.post(
    CoustomUrls.getCampaigns,
    {
      UserKey: payload.userKey,
      page: payload.page
    },
    [
      {
        key: 'AuthenticationKey',
        value: process.env.REACT_APP_VOUCHERS_AUTHENTICATIONKEY
      }
    ],
    false
  )
  if (STATUS.isSuccess(res.status)) {
    yield put({
      type: PROFILE.CAMPAIGNS_LOADED,
      campaigns: res.response
    })
  } else {
    yield put({
      type: PROFILE.CAMPAIGNS_FAILED
    })
  }
}

export default function* profile() {
  yield takeLatest(PROFILE.LOAD_CAMPAIGNS, loadCampaignsSaga)
}
