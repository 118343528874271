import { normalizeCategories } from "../../views/servicesTool/utils";
import { MEDICINE } from "../actions/medicine";

const initialState = {
  categories: {},
  types: {},
};

export default (state = initialState, { type, ...action }) => {
  switch (type) {
    case MEDICINE.CATEGORIES.SUCCEEDED: {
      return {
        ...state,
        categories: normalizeCategories(action.categories),
      };
    }

    case MEDICINE.TYPES.SUCCEEDED:
      return {
        ...state,
        types: { items: action.types },
      };
    default:
      return state;
  }
};
