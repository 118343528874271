import { PROFILE } from '../actions/profile'

const initialState = {
    profile: null,
    loading: false,
    loaded: false,
    loadingCampaigns: false,
    loadedCampaigns: false,
    campaigns:null,
}

export default (state = initialState, {type, ...payload}) => {
    switch(type) {
        case PROFILE.LOAD:
            return {
                ...state,
                loading: true,
                loaded: false,
            }
        case PROFILE.LOADED:
            return {
                ...state,
                loading: false,
                loaded: true,
                profile: {
                    ...state.profile,
                    key: payload.profile
                } 
            }
        case PROFILE.FAILED: 
            return {
                ...state,
                loading: false,
                loaded: false,
            }
        case PROFILE.LOAD_CAMPAIGNS: 
            return {
                ...state,
                loadingCampaigns: true,
                loadedCampaigns: false,
            }
        case PROFILE.CAMPAIGNS_LOADED: 
            return {
                ...state,
                loadingCampaigns: false,
                loadedCampaigns: true,
                campaigns: payload.campaigns
            }
        case PROFILE.CAMPAIGNS_FAILED:
            return {
                ...state,
                loadingCampaigns: false,
                loadedCampaigns: false
            }
        default: return state
    }
}