import { CITY } from '../actions/city'


const initialState = {
    cities: [],
    citiesMap: new Map(),
    loaded: false,
    loading: false,
}

export default (state = initialState, {type, ...payload}) => {
    switch(type) {
        case CITY.LOAD:
            return Object.assign({}, state, { loaded: false }, { loading: true})
        case CITY.LOAD_SUCCESS: {
            const citiesMap = new Map()
            payload.cities.forEach(
                city => citiesMap.set(city.Id, city)
            )
            return {
                ...state,
                cities: [...payload.cities],
                citiesMap,
                loaded: true,
                loading: false,
            }
        }
        case CITY.LOAD_FAILED:
            return {
                ...state,
                loaded: false,
                loading: false
            }
        default: 
            return state;
    }
}