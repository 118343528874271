export const VOUCHERS = {
    SUBMIT: 'VOUCHERS_SUBMIT',
    SUBMIT_FAILED: 'VOUCHERS_SUBMIT_FAILED',
    SUBMIT_SUCCESS: 'VOUCHERS_SUBMIT_SUCCESS',
    CLEAR_RESPONSE_MESSAGE: 'CLEAR_RESPONSE_MESSAGE'
}

export const submitVoucher = payload => ({
    type: VOUCHERS.SUBMIT,
    payload
})

export const clearResponseMessage = payload => ({
    type: VOUCHERS.CLEAR_RESPONSE_MESSAGE,
    payload
})