import { VOUCHERS } from '../actions/vouchers'
const initialState = {
    message: '',
    promocodes: [],
    submitting: false,
    submitted: false,
}

export default (state = initialState, { type, ...payload}) => {
    switch(type) {
        case VOUCHERS.SUBMIT:
            return {
                ...state,
                submitting: true,
                submitted: false,
            }
        case VOUCHERS.SUBMIT_SUCCESS: 
            return {
                ...state,
                message: payload.message,
                promocodes: payload.promocodes,
                submitted: true,
                submitting: false,
            }
        case VOUCHERS.SUBMIT_FAILED:
            return {
                ...state,
                message: payload.message,
                submitted: false,
                submitting: false
            }
        case VOUCHERS.CLEAR_RESPONSE_MESSAGE:
            return {
                ...state,
                message: ''
            }
        default: return state
    }
}