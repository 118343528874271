import { takeLatest, put} from 'redux-saga/effects'
import { API, STATUS } from '@vezeeta/web-utils'
import  CoustomUrls  from '../../urls'

import { CAMPAIGN } from '../actions/summary'

function* stopCampaign({ payload }) {
    const api = new API()
    const res = yield api.post(CoustomUrls.stopCampaign, 
        {
            UserKey: payload.userKey? payload.userKey : '',
            campaignId: payload.campaignId? payload.campaignId : ''
        },
        [
            {
                key: 'AuthenticationKey',
                value: process.env.REACT_APP_VOUCHERS_AUTHENTICATIONKEY
            }
        ],
        false
    )
    if(STATUS.isSuccess(res.status))
        yield put({
            type: CAMPAIGN.STOP_CAMPAIGN_SUCCESS,
            message: `Campaign stopped successfully`
        })
    else 
        yield put({
            type: CAMPAIGN.STOP_CAMPAIGN_FAILED,
            message: res.response
        })
}

export default function* summary() {
    yield takeLatest(CAMPAIGN.STOP_CAMPAIGN, stopCampaign)
}