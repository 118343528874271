import { takeLatest, put} from 'redux-saga/effects'
import { API, STATUS } from '@vezeeta/web-utils'
import  CoustomUrls  from '../../urls'

import { VOUCHERS } from '../actions/vouchers'
import { formatSubmitData } from '../../views/servicesTool/utils'

function* submitVoucher({ payload }) {
    const api = new API()
    const data = yield formatSubmitData(payload)
    const res = yield api.post(CoustomUrls.postVoucher,data,
        [
            {
                key: 'AuthenticationKey',
                value: process.env.REACT_APP_VOUCHERS_AUTHENTICATIONKEY
            }
        ],
        false
    )
    if(STATUS.isSuccess(res.status))
        yield put({
            type: VOUCHERS.SUBMIT_SUCCESS,
            message: `Promocode submitted successfully`,
            promocodes: res.response
        })
    else 
        yield put({
            type: VOUCHERS.SUBMIT_FAILED,
            message: res.response
        })
}

export default function* voucher() {
    yield takeLatest(VOUCHERS.SUBMIT, submitVoucher)
}