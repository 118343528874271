import { takeEvery, put } from 'redux-saga/effects'
import { API, STATUS } from '@vezeeta/web-utils'
import  CoustomUrls  from '../../urls'

import { AREAS } from '../actions/area'

function* getAreas({ payload }) {
    const api = new API()
    const res = yield api.get(`${CoustomUrls.getAreasByCityId}?cityid=${payload.city}`, undefined, true)
    console.log(res);
    if(STATUS.isSuccess(res.status)) 
        yield put({
            type: AREAS.LOAD_DONE,
            areas: res.data
        })
    else
        yield put({
            type: AREAS.LOAD_FAILED
        })
}

export default function* area() {
    yield takeEvery(AREAS.LOAD, getAreas)
}