import { combineReducers } from 'redux';

import country from './country';
import medicine from './medicine';
import specialties from './specialties';
import services from './services';
import units from './units';
import cities from './city'
import areas from './area'
import vouchers from './vouchers'
import profile from './profile'
import summary from './summary'
import general from './general'
import product from './product'

export default combineReducers({
  services,
  units,
  country,
  medicine,
  specialties,
  cities,
  areas,
  vouchers,
  profile,
  summary,
  general,
  product
});
