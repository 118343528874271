export const CITY = {
    LOAD: 'CITY_LOAD',
    LOAD_FAILED: 'CITY_LOAD_FAILED',
    LOAD_SUCCESS: 'CITY_LOAD_DONE'
}

export const loadCity = (payload) => ({
        type: CITY.LOAD,
        payload
})
  
