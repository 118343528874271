const CoustomUrls = {
  signIn: `${process.env.REACT_APP_VOUCHERS_URL}/api/user/login`,
  registerPassword: `${process.env.REACT_APP_VOUCHERS_URL}/api/user/RegisterPassword`,
  medicine:{
    types:`${process.env.REACT_APP_API_INVINTORY_MICRO_SERVICE}/DiseaseType`,
  categories:`${process.env.REACT_APP_API_INVINTORY_MICRO_SERVICE}/category`
},
  getAllUnits: `${process.env.REACT_APP_OFFERS_URL}/Attribute/GetAllUnits`,
  getUnitDetails: `${process.env.REACT_APP_OFFERS_URL}/Attribute/GetUnitDetails`,
  getUnitsPerCountry: `${process.env.REACT_APP_OFFERS_URL}/Attribute/GetUnitsPerCountry`,
  addEditUnit: `${process.env.REACT_APP_OFFERS_URL}/Attribute/AddUnit`,
  getAllServices: `${process.env.REACT_APP_OFFERS_URL}/portal/GetAllServicesByLevel`,
  getServiceDetails: `${process.env.REACT_APP_OFFERS_URL}/portal/GetServiceDetails`,
  addService: `${process.env.REACT_APP_OFFERS_URL}/portal/AddServiceAcrossCountries`,
  updateService: `${process.env.REACT_APP_OFFERS_URL}/portal/updateService`,
  getCitiesByCountryId: `${process.env.REACT_APP_STATIC_URL}/api/city/GetCitiesByCountryId`,
  getAreasByCityId: `${process.env.REACT_APP_STATIC_URL}/api/Area/GetAreasByCityId`,
  postVoucher: `${process.env.REACT_APP_VOUCHERS_URL}/api/voucher/CreateCampaign`,
  getSpecialties: `${process.env.REACT_APP_VEZEETA_NATIVE_URL}/api/Specialities/GetMainSpecialities`,
  getCampaigns: `${process.env.REACT_APP_VOUCHERS_URL}/api/voucher/GetCampaigns`,
  stopCampaign: `${process.env.REACT_APP_VOUCHERS_URL}/api/voucher/StopCampaign`,
  getCountries: `${process.env.REACT_APP_STATIC_URL}/api/Country/GetCountries?isVirtual=true`,
  getProducts: `${process.env.REACT_APP_VOUCHERS_URL}/api/voucher/GetProducts`
};
export default CoustomUrls;
