import { fork } from 'redux-saga/effects';

import country from './country';
import medicine from './medicine';
import specialtiesSaga from './specialties';
import servicesSaga from './services';
import unitsSaga from './units';
import citySaga from './city';
import areaSaga from './area'
import vouchersSaga from './vouchers'
import profileSaga from './profile'
import campaignSaga from './summary'
import productSaga from './product'

const sagas = [servicesSaga, unitsSaga, country,medicine, specialtiesSaga, citySaga, areaSaga, vouchersSaga, profileSaga, campaignSaga, productSaga];

export default function* rootSaga() {
  yield sagas.map(saga => fork(saga));
}
