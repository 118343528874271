import { PRODUCTS } from '../actions/product'
const initialState = {
    products: [],
    loaded: false,
    loading: false,
}

export default (state = initialState, {type, ...payload}) => {
    switch(type) {
        case PRODUCTS.LOAD:
            return {
                ...state,
                loaded: false,
                loading: true,
            }
        case PRODUCTS.LOAD_DONE: 
            return {
                ...state,
                products: [...payload.products],
                loaded: true,
                loading: false,
            }
        case PRODUCTS.LOAD_FAILED:
            return {
                ...state,
                loaded: false,
                loading: false,
            }
          
        default: return state
    }
}