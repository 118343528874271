import { takeLatest, put } from 'redux-saga/effects';
import { STATUS, API, jsonToQueryString } from '@vezeeta/web-utils';
import Cookies from 'js-cookie';

import CoustomUrls from '../../urls';
import {
  SERVICES,
  fetchServices,
  fetchServicesSuccess,
  fetchServicesFail,
  fetchServiceDetailsSuccess,
  fetchServiceDetailsFail,
  addEditServiceSuccess,
  addEditServiceFail,
  addEditServiceConflicted,
  fetchParentGroupsSuccess,
  fetchParentGroupsFail
} from '../actions/services';

const auth = Cookies.get('AUTH_TOKEN');

function* fetchServicesList(action) {
  if (auth) {
    const headers = [
      {
        key: 'languageId',
        value: '1'
      },
      {
        key: 'Authorization',
        value: `bearer ${auth}`
      }
    ];
    const body = {
      levelId: action.levelId,
      pageIndex: action.pageIndex,
      pageSize: action.pageSize
    };

    const apiWrapper = yield new API();
    const response = yield apiWrapper.get(
      `${CoustomUrls.getAllServices}?${jsonToQueryString(body)}`,
      headers,
      false
    );

    if (STATUS.isSuccess(response.status)) {
      yield put(fetchServicesSuccess(response.data));
    } else if (STATUS.isNotAuthorized(response.status)) {
      Cookies.remove('AUTH_TOKEN');
      window.location.href = '/';
    } else {
      yield put(fetchServicesFail(response));
    }
  } else {
    Cookies.remove('AUTH_TOKEN');
    window.location.href = '/';
  }
}

function* fetchParentGroups(action) {
  if (auth) {
    const headers = [
      {
        key: 'languageId',
        value: '1'
      },
      {
        key: 'Authorization',
        value: `bearer ${auth}`
      }
    ];
    const body = {
      levelId: action.levelId,
      pageIndex: action.pageIndex,
      pageSize: action.pageSize
    };

    const apiWrapper = yield new API();
    const response = yield apiWrapper.get(
      `${CoustomUrls.getAllServices}?${jsonToQueryString(body)}`,
      headers,
      false
    );

    if (STATUS.isSuccess(response.status)) {
      yield put(fetchParentGroupsSuccess(response.data));
    } else if (STATUS.isNotAuthorized(response.status)) {
      Cookies.remove('AUTH_TOKEN');
      window.location.href = '/';
    } else {
      yield put(fetchParentGroupsFail(response));
    }
  } else {
    Cookies.remove('AUTH_TOKEN');
    window.location.href = '/';
  }
}

function* fetchServiceDetails(action) {
  if (auth) {
    const headers = [
      {
        key: 'languageId',
        value: '1'
      },
      {
        key: 'Authorization',
        value: `bearer ${auth}`
      }
    ];

    const body = {
      serviceKey: action.serviceKey
    };

    const apiWrapper = yield new API();
    const response = yield apiWrapper.get(
      `${CoustomUrls.getServiceDetails}?${jsonToQueryString(body)}`,
      headers,
      false
    );
    if (STATUS.isSuccess(response.status)) {
      yield put(fetchServiceDetailsSuccess(response.data));
    } else if (STATUS.isNotAuthorized(response.status)) {
      Cookies.remove('AUTH_TOKEN');
      window.location.href = '/';
    } else {
      yield put(fetchServiceDetailsFail(response));
    }
  } else {
    Cookies.remove('AUTH_TOKEN');
    window.location.href = '/';
  }
}

function* addEditService(action) {
  if (auth) {
    const headers = [
      {
        key: 'Authorization',
        value: `bearer ${auth}`
      }
    ];
    const apiWrapper = yield new API();
    const response = yield apiWrapper.post(CoustomUrls.addService, action.serviceObj, headers);
    if (STATUS.isSuccess(response.status)) {
      yield [
        put(addEditServiceSuccess(response.data)),
        put(fetchServices(action.serviceObj.levelId, 1, 5))
      ];
    } else if (STATUS.isConflict(response.status)) {
      yield [
        put(addEditServiceConflicted(response.response.errorMessage)),
        put(fetchServices(action.serviceObj.levelId, 1, 5))
      ];
    } else if (STATUS.isNotAuthorized(response.status)) {
      Cookies.remove('AUTH_TOKEN');
      window.location.href = '/';
    } else {
      yield put(addEditServiceFail(response));
    }
  } else {
    Cookies.remove('AUTH_TOKEN');
    window.location.href = '/';
  }
}

function* servicesSaga() {
  yield takeLatest(SERVICES.FETCH.LOAD, fetchServicesList);
  yield takeLatest(SERVICES.FETCH_PARENT_GROUP.LOAD, fetchParentGroups);
  yield takeLatest(SERVICES.FETCH_DETAILS.LOAD, fetchServiceDetails);
  yield takeLatest(SERVICES.ADD_EDIT.LOAD, addEditService);
}

export default servicesSaga;
