import { takeEvery, put } from 'redux-saga/effects'
import { API, STATUS } from '@vezeeta/web-utils'
import  CoustomUrls  from '../../urls'

import { CITY } from '../actions/city'

function* getCities({ payload }) {
        const api = new API()
        const res = yield api.get(`${CoustomUrls.getCitiesByCountryId}?countryId=${payload}`, undefined, true)
        //console.log(res);
        if(STATUS.isSuccess(res.status)) {
            
            yield put({
                type: CITY.LOAD_SUCCESS,
                cities: res.data
            })
        } else {
            yield put({
                type: CITY.LOAD_FAILED
            })
        }
}

export default function* city() {
    yield takeEvery(CITY.LOAD, getCities)
}