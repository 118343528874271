export const AREAS = {
    LOAD: 'AREAS_LOAD',
    LOAD_FAILED: 'AREAS_LOAD_FAILED',
    LOAD_DONE: 'AREAS_LOAD_DONE',
    RESET: 'AREAS_RESET'
}

export const loadAreas = (type, payload) => ({
    type: !type? AREAS.LOAD: type,
    payload
})