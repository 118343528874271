import { takeEvery, put } from 'redux-saga/effects'
import { API, STATUS } from '@vezeeta/web-utils'
import CoustomUrls from '../../urls'

import { PRODUCTS } from '../actions/product'
import { productsEnum } from '../../enums/product'

function* getProducts({ payload }) {
    const api = new API()
    const res = yield api.get(`${CoustomUrls.getProducts}?productTypeId=${productsEnum.BookingType}`, [
        {
            key: 'AuthenticationKey',
            value: process.env.REACT_APP_VOUCHERS_AUTHENTICATIONKEY
        }
    ], true)

    if (STATUS.isSuccess(res.status))
        yield put({
            type: PRODUCTS.LOAD_DONE,
            products: res.response
        })
    else
        yield put({
            type: PRODUCTS.LOAD_FAILED
        })
}

export default function* product() {
    yield takeEvery(PRODUCTS.LOAD, getProducts)
}