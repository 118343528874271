// @flow

export const MEDICINE = {
  CATEGORIES:{
    LOADING: 'MEDICINE_CATEGORIES_LOADING',
    SUCCEEDED: 'MEDICINE_CATEGORIES_SUCCEEDED',
    FAILED: 'MEDICINE_CATEGORIES_FAILED',
  },
  TYPES:{
    LOADING: 'MEDICINE_TYPES_LOADING',
    SUCCEEDED: 'MEDICINE_TYPES_SUCCEEDED',
    FAILED: 'MEDICINE_TYPES_FAILED',
  }
};

export const loadCategories = (pageNumber,pageSize) => ({
  type: MEDICINE.CATEGORIES.LOADING,
  payload:{pageNumber,pageSize}
});

export const loadTypes = () => ({
  type: MEDICINE.TYPES.LOADING
});
