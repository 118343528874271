/* global process */
import React, { PureComponent } from 'react';
import Loadable from 'react-loadable';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { Loading, Spinner, SetGlobalStyle, Header } from '@vezeeta/web-components';
import Cookies from 'js-cookie';
import { Box } from 'grid-styled';
import { Provider } from 'react-redux';
import '@vezeeta/enterprise-modals/lib/Modal.scss';
import store from '../store';

// Async loading components

const Login = Loadable({
  loader: () => import('./login/Login'),
  loading: Loading
});

const ServicesTool = Loadable({
  loader: () => import('./servicesTool/ServicesToolContainer'),
  loading: Loading
});

const routes = [
  {
    component: Login,
    route: '/login'
  },
  {
    component: ServicesTool,
    route: '/services'
  }
];

class App extends PureComponent {
  constructor(props) {
    super(props);

    SetGlobalStyle();

    this.state = {
      isLoading: false
    };
  }

  componentDidMount() {
    // Set the background image
    document.body.style.backgroundImage = `url("${
      process.env.REACT_APP_CDN_URL
    }/assets/background-sm.png")`;

    this.backgroundImg.onload = () => {
      document.body.style.backgroundImage = `url("${
        process.env.REACT_APP_CDN_URL
      }/assets/background.png")`;
    };
  }

  onLogout = () => {
    Cookies.remove('AUTH_TOKEN');
    window.location.pathname = '/';
  };

  render() {
    let body;
    const headerItems = null
      // <DropDown
      //   items={[
      //     {
      //       icon: IconsStore.getIcon('logout'),
      //       text: 'Logout',
      //       onClick: this.onLogout
      //     }
      //   ]}
      //   arrowColor="#fff"
      // />
    

    if (!this.state.isLoading) {
      body = (
        <Box>
          <Switch>
            {routes.map(route => (
              <Route key={route.route} path={route.route} component={route.component} />
            ))}
            <Redirect to="/login" />
          </Switch>
        </Box>
      );
    } else {
      body = (
        <div className="loading-routes">
          <Spinner />
        </div>
      );
    }

    return (
      <Provider store={store}>
        <Router history={this.browserHistory}>
          <div className="App">
            <img
              src={`${process.env.REACT_APP_CDN_URL}/assets/background.png`}
              ref={img => {
                this.backgroundImg = img;
              }}
              className="hide"
              alt="logo"
            />
            <Header
              items={
                window.location.pathname === '/login' || window.location.pathname === '/'
                  ? null
                  : headerItems
              }
              onLogoClick={() => {
                window.location = window.location.href;
              }}
              logoUrl={`${process.env.REACT_APP_CDN_URL}/assets/logo.png`}
              px={8}
            />
            {body}
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
