export const SERVICES = {
  FETCH: {
    LOAD: 'SERVICES_FETCH',
    SUCCESS: 'SERVICES_FETCH_SUCCESS',
    FAIL: 'SERVICES_FETCH_FAIL'
  },
  FETCH_PARENT_GROUP: {
    LOAD: 'PARENT_GROUP_FETCH',
    SUCCESS: 'PARENT_GROUP_FETCH_SUCCESS',
    FAIL: 'PARENT_GROUP_FETCH_FAIL'
  },
  FETCH_DETAILS: {
    LOAD: 'SERVICES_FETCH_DETAILS',
    SUCCESS: 'SERVICES_FETCH_DETAILS_SUCCESS',
    FAIL: 'SERVICES_FETCH_DETAILS_FAIL'
  },
  ADD_EDIT: {
    LOAD: 'SERVICES_ADD_EDIT',
    SUCCESS: 'SERVICES_ADD_EDIT_SUCCESS',
    FAIL: 'SERVICES_ADD_EDIT_FAIL',
    CONFLICT: 'SERVICES_ADD_EDIT_CONFLICT'
  },
  SNACK_BAR: {
    HIDE: 'SERVICES_SNACK_BAR_HIDE'
  },
  MODAL: {
    TOGGLE_DELETE: 'DELETE_MODAL'
  }
};

export const fetchServices = (levelId, pageIndex, pageSize) => ({
  type: SERVICES.FETCH.LOAD,
  levelId,
  pageIndex,
  pageSize
});

export const fetchServicesSuccess = services => ({
  type: SERVICES.FETCH.SUCCESS,
  services
});

export const fetchServicesFail = () => ({
  type: SERVICES.FETCH.FAIL
});

export const fetchParentGroups = (levelId, pageIndex, pageSize) => ({
  type: SERVICES.FETCH_PARENT_GROUP.LOAD,
  levelId,
  pageIndex,
  pageSize
});

export const fetchParentGroupsSuccess = parentGroups => ({
  type: SERVICES.FETCH_PARENT_GROUP.SUCCESS,
  parentGroups
});

export const fetchParentGroupsFail = () => ({
  type: SERVICES.FETCH_PARENT_GROUP.FAIL
});

export const fetchServiceDetails = serviceKey => ({
  type: SERVICES.FETCH_DETAILS.LOAD,
  serviceKey
});

export const fetchServiceDetailsSuccess = serviceDetails => ({
  type: SERVICES.FETCH_DETAILS.SUCCESS,
  serviceDetails
});

export const fetchServiceDetailsFail = () => ({
  type: SERVICES.FETCH_DETAILS.FAIL
});

export const addEditService = serviceObj => ({
  type: SERVICES.ADD_EDIT.LOAD,
  serviceObj
});

export const addEditServiceSuccess = services => ({
  type: SERVICES.ADD_EDIT.SUCCESS,
  services
});

export const addEditServiceFail = () => ({
  type: SERVICES.ADD_EDIT.FAIL
});

export const addEditServiceConflicted = error => ({
  type: SERVICES.ADD_EDIT.CONFLICT,
  error
});

export const setServicesSnackBarAsHidden = () => ({
  type: SERVICES.SNACK_BAR.HIDE
});

export const toggleDeleteServiceModal = showModal => ({
  type: SERVICES.MODAL.TOGGLE_DELETE,
  showModal
});
