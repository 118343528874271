import { SERVICES } from '../actions/services';

const initialState = {
  services: {
    list: [],
    filteredList: [],
    state: undefined
  },
  parentGroups: {
    parentGroups: [],
    filteredList: [],
    state: undefined
  },
  fetchDetails: {
    serviceDetails: { services: [] },
    serviceKey: undefined,
    state: undefined
  },
  add_edit: {
    list: [],
    state: undefined
  },
  snackBar: { show: false, message: '' },
  deleteServiceModal: {
    show: false,
    serviceDetails: { services: [] }
  }
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {
    case SERVICES.FETCH.LOAD:
      return {
        ...state,
        services: {
          list: [],
          filteredList: [],
          state: SERVICES.FETCH.LOAD,
          loaded: false
        },
        snackBar: { show: false, message: '' }
      };

    case SERVICES.FETCH.SUCCESS: {
      return {
        ...state,
        services: {
          list: payload.services,
          filteredList: payload.services,
          state: SERVICES.FETCH.SUCCESS,
          loaded: true
        }
      };
    }

    case SERVICES.FETCH.FAIL:
      return {
        ...state,
        services: {
          list: [],
          filteredList: [],
          state: SERVICES.FETCH.FAIL,
          loaded: false
        }
      };

    case SERVICES.FETCH_PARENT_GROUP.LOAD:
      return {
        ...state,
        parentGroups: {
          parentGroups: [],
          filteredList: [],
          state: SERVICES.FETCH_PARENT_GROUP.LOAD,
          loaded: false
        }
      };

    case SERVICES.FETCH_PARENT_GROUP.SUCCESS: {
      return {
        ...state,
        parentGroups: {
          parentGroups: payload.parentGroups,
          filteredList: payload.parentGroups,
          state: SERVICES.FETCH_PARENT_GROUP.SUCCESS,
          loaded: true
        }
      };
    }

    case SERVICES.FETCH_PARENT_GROUP.FAIL:
      return {
        ...state,
        parentGroups: {
          parentGroups: [],
          filteredList: [],
          state: SERVICES.FETCH_PARENT_GROUP.FAIL,
          loaded: false
        }
      };

    case SERVICES.FETCH_DETAILS.LOAD:
      return {
        ...state,
        fetchDetails: {
          serviceDetails: { services: [] },
          serviceKey: payload.serviceKey,
          state: SERVICES.FETCH_DETAILS.LOAD
        }
      };

    case SERVICES.FETCH_DETAILS.SUCCESS: {
      return {
        ...state,
        fetchDetails: {
          ...state.fetchDetails,
          serviceDetails: payload.serviceDetails,
          state: SERVICES.FETCH_DETAILS.SUCCESS
        }
      };
    }

    case SERVICES.FETCH_DETAILS.FAIL:
      return {
        ...state,
        fetchDetails: {
          ...state.fetchDetails,
          serviceDetails: { services: [] },
          state: SERVICES.FETCH_DETAILS.FAIL
        }
      };

    case SERVICES.ADD_EDIT.LOAD:
      return {
        ...state,
        add_edit: {
          list: [],
          filteredList: [],
          state: SERVICES.ADD_EDIT.LOAD
        }
      };

    case SERVICES.ADD_EDIT.SUCCESS: {
      return {
        ...state,
        add_edit: {
          list: payload.services,
          filteredList: payload.services,
          state: SERVICES.ADD_EDIT.SUCCESS
        },
        snackBar: { show: true, message: 'Service Saved successfully' }
      };
    }

    case SERVICES.ADD_EDIT.FAIL:
      return {
        ...state,
        add_edit: {
          list: [],
          filteredList: [],
          state: SERVICES.ADD_EDIT.FAIL
        }
      };

    case SERVICES.ADD_EDIT.CONFLICT: {
      return {
        ...state,
        add_edit: {
          list: [],
          filteredList: [],
          state: SERVICES.ADD_EDIT.CONFLICT
        },
        snackBar: { show: true, message: payload.error }
      };
    }

    case SERVICES.SNACK_BAR.HIDE:
      return {
        ...state,
        snackBar: {
          show: false,
          message: ''
        }
      };

    case SERVICES.MODAL.TOGGLE_DELETE: {
      return Object.assign({}, state, {
        deleteServiceModal: { show: payload.showModal, serviceDetails: { services: [] } }
      });
    }

    default:
      return state;
  }
};
