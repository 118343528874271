export const PRODUCT_TYPES = { EPHARMACY: "Pro633" };

export const createOption = (
  label,
  value,
  img,
  key,
  disabled,
  groupBy,
  groupIcon,
  searchable,
  object
) => ({
  fieldValue: label,
  value,
  searchable: searchable || [label],
  fieldImage: img,
  key,
  children: label,
  disabled,
  groupBy,
  groupIcon,
});

export const getCountriesOptions = (countries) => {
  if (countries && countries.length !== 0) {
    const countriesOptions = countries.map((country) => {
      const countryLangItem = country.LanguageItemModels.find(
        (i) => i.LanguageId == 1
      );
      return createOption(countryLangItem.Name, country, null, country.ISOCode);
    });
    return countriesOptions;
  }
  return undefined;
};

export const getCitiesOptions = (cities) => {
  if (cities && cities.length !== 0) {
    const citiesOptions = cities.map((city) => {
      const cityLangItem = city.LanguageItemModels.find(
        (i) => i.LanguageId == 1
      );
      return createOption(cityLangItem.Name, city.Id, null, city.CityKey);
    });
    return citiesOptions;
  }
  return undefined;
};

export const getAreasOptions = (areas) => {
  if (areas && areas.length !== 0) {
    const areasOptions = areas.map((area) => {
      const areaLangItem = area.LanguageItemModels.find(
        (i) => i.LanguageId == 1
      );
      return createOption(areaLangItem.Name, area.Id, null, area.AreaKey);
    });
    return areasOptions;
  }
  return undefined;
};

export const getSpecialtiesOptions = (specialties) => {
  if (specialties) {
    const allSpecialtiesOption = createOption(
      "All Specialties",
      "All specialties",
      null,
      ""
    );
    const specialtiesOptions = [];
    specialtiesOptions.push(allSpecialtiesOption);
    specialties.forEach((specialty, key) =>
      specialtiesOptions.push(
        createOption(specialty.SpecialityNameEnglish, specialty, null, key)
      )
    );
    return specialtiesOptions;
  }
  return undefined;
};

export const formatEntities = (categories, types) => {
  return [
    ...categories.map((key) => {
      return { entityKey: key, entitytypeid: 1 };
    }),
    ...types.map((key) => {
      return { entityKey: key, entitytypeid: 2 };
    }),
  ];
};

export const formatSubmitData = (payload) => {
  if (payload.ProductKey === PRODUCT_TYPES.EPHARMACY)
    return {
      CampaignName: payload.campaignName,
      TypeId: payload.amount ? 1 : 2,
      Value: payload.amount ? payload.amount : payload.percentage,
      RepetitionType: 4,
      UsageLimit: payload.limit,
      UsageLimitPerUser: payload.limitPerUser,
      SpecialityKey: payload.specialty.SpecialityKey,
      AreaKey: payload.areaKey,
      CityKey: payload.cityKey,
      CountryKey: payload.country,
      Count: payload.promocode ? 1 : payload.count,
      Name: payload.promocode,
      StartDateTime: `${payload.startDate} 00:00:00`,
      EndDateTime: `${payload.endDate} 00:00:00`,
      CreatedBy: payload.key ? payload.key : "",
      Share: payload.vshare,
      ProviderKey: payload.providerKey,
      MaxDiscount: payload.MaxDiscount,
      MinRecieptValue: payload.MaxDiscount,
      ReciepientKey: "",
      ProductKey: payload.ProductKey,
      MinRecieptValue: payload.cartValue,
      ComponentKey: "",
      OfferCheckKey: payload.OfferCheckKey,
      entities: formatEntities(payload.categories, payload.types),
      LimitedForNewUser: payload.newUser,
    };
  else {
    return {
      CampaignName: payload.campaignName,
      TypeId: payload.amount ? 1 : 2,
      Value: payload.amount ? payload.amount : payload.percentage,
      RepetitionType: 4,
      UsageLimit: payload.limit,
      UsageLimitPerUser: payload.limitPerUser,
      SpecialityKey: payload.specialty.SpecialityKey,
      AreaKey: payload.areaKey,
      CityKey: payload.cityKey,
      CountryKey: payload.country,
      Count: payload.promocode ? 1 : payload.count,
      Name: payload.promocode,
      StartDateTime: `${payload.startDate} 00:00:00`,
      EndDateTime: `${payload.endDate} 00:00:00`,
      CreatedBy: payload.key ? payload.key : "",
      Share: payload.vshare,
      ProviderKey: payload.providerKey,
      Cap: payload.cap,
      ReciepientKey: "",
      ProductKey: payload.ProductKey,
      ComponentKey: "",
      OfferCheckKey: payload.OfferCheckKey,
      MaxDiscount: payload.MaxDiscount,
    };
  }
};

export const normalizeCategories = (categories) => {
  categories.items = categories.results.map((result) => {
    const name = result.categoryTranslators[0].name;
    return {
      children: name,
      fieldValue: name,
      key: result.key,
      value: result.key,
      searchable: [name],
    };
  });
  delete categories.results;
  return categories;
};


export const matchPositiveInt = (val) => val.match(/[0-9]*/)[0];

export const matchPositiveFloat = (val) => {
  const noInitialDot = val[0] === "." ? "" : val;
  return noInitialDot.match(/[0-9]*\.?[0-9]*/)[0];
};


